<template>
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <h3 class="mb-0">
              {{ lista_campos != "" ? "Editar" : "Criar" }} Contrato Servicos
            </h3>
          </div>
        </div>
        <div class="card-body">
          <form>
            <div class="form-group row">
              <div class=" col-md-6">
                <label class="col-md-12">Nome:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.nome"
                  placeholder="Digite o Nome"
                />
              </div>
              <div class="col-md-6">
                <label class="col-md-12"> Servicos:*</label>
                <treeselect
                  :multiple="true"
                  :options="lista_servicos"
                  placeholder="Selecione os servicos..."
                  v-model="form.servicos_id"
                />
              </div>
              <div class="col-md-6">
                <label class="col-md-12">Contrato:*</label>
                <treeselect
                  :multiple="false"
                  :options="lista_produtos"
                  placeholder="Selecione o contrato..."
                  v-model="form.produto_id"
                />
              </div>
            <div class="col-md-6">
              <label class="col-md-12">Valor:*</label>
              <input
                required
                type="text"
                class="form-control"
                v-model="form.valor"
                @input="valorFormat"
                v-mask="variableWithMask"
                placeholder="Digite o valor"
              />
            </div>
          </div>

            <div class="row form-group">
              <InputFile text="Documeneto contrato*" ref="principal" />
              
              <div v-if="arquivos" class="col-md-12 font-weight">
                Documento Contrato
                <br>

               <v-btn color="primary" v-for="(lista,j) in arquivos" :key="j"  elevation="9" @click="ircon(lista.id)">{{lista.nome_original  }}</v-btn>   
            </div>
            </div>

            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button
                  @click.prevent="confirm(!lista_campos.id ? 'cria' : 'edita')"
                  class="btn btn-primary"
                  :disabled="verif"
                >
                  Salvar
                  <b-spinner
                    v-show="verif"
                    small
                    variant="dark"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import InputFile from "@/core/helpers/InputFile";
import JwtService from "@/core/services/jwt.service";

export default {
  components: { InputFile },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Contrato Serviços" }]);
  },
  mixins: [fireAlert],
  data() {
    return {
      form: {
        produto_id: null,
        servicos_id: null,
        valor: null,
        nome: null,

      },
      arquivo_id: null,
      arquivos:[],
      variableWithMask: "",
      verif: false,
      currentPage: 1,
      perPage: 10,
      editarProduto: true,
      host: process.env.VUE_APP_API_URL + "/arquivo/doc/",
      token: JwtService.getToken(),

    };
  },
  created() {
    this.preenxerCampos();
  },
  computed: {
    lista_servicos() {
      return this.$store.state.servico.lista_servicos.map((cat) => ({
        id: cat.id,
        label: cat.nome,
      }));
    },
    lista_produtos() {
      return this.$store.state.produto.lista_produtos.map((cat) => ({
        id: cat.id,
        label: cat.nome,
      }));
    },
    lista_campos() {
      // return JSON.parse(localStorage.getItem("produto"));
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.contratoServico.mensagem_alert;
    },
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` um Contrato Serviços no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      if (this.$refs.principal) {
        this.arquivo_id = await this.$refs.principal.submit();
      }
      this.form.valor = this.form.valor.replace("R$", " ").replace(",", ".");
let arquivos_id = this.arquivo_id
console.log( this.arquivo_id)
let form = {cs:this.form,arquivo_id:arquivos_id}
      this.verif = true;
      await this.$store.dispatch("contratoServico/create_contrato_servico",form );
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "contratoServico",
      });
    },
    async update() {
      this.verif = true;
      await this.$store.dispatch("contratoServico/update_contrato_servicos", this.form);
      this.verif = false;

      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "contratoServico",
      });
    },

    async preenxerCampos() {
      await this.$store.dispatch("produto/listar_produtos");
      await this.$store.dispatch("servico/listar_servicos");
console.log(this.lista_campos)
      if (this.lista_campos != ""){
        this.form = {
          id: this.lista_campos.id,
          nome:this.lista_campos.nome,
          produto_id: this.lista_campos.produto_id,
          servicos_id: this.lista_campos.servicos_id,
          valor: this.lista_campos.valor,
        


          // user_up: this.lista_campos.user_up,
        };
        this.arquivos= this.lista_campos.arq

      }
    },
    valorFormat() {
      let pa = "";
      for (let i = 0; i < this.form.valor.length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }
      this.variableWithMask = "R$" + pa + "#,##";
    },
    ircon(value){
      window.open( this.host + value + '?token=' + this.token,'_blank')
    }
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgb(167, 183, 255);
}
</style>